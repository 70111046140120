import React, { Component } from 'react';
import Header from '../components/header';
import MenuList from '../components/Menu';
import testMenuList from '../TestAssets/testMenuList.json';

export default class MenuPage extends Component {

    keys = Object.keys(testMenuList);

    render() {

        return (
            <>
                <Header />
                <div className="grid grid-cols-2 gap-5 mx-auto px-3 text-center h-auto xs:w-80 w-[83rem]">
                    {
                        this.keys.map(key =>
                            <div className="py-5">
                                <p className="text-white text-xl neonMenuHeader">{key}</p>
                                <MenuList menu={testMenuList[key]} menuHeader={key} />
                            </div>

                        )
                    }
                </div>
            </>
        );
    }
}