import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { brands, solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'



export default class Footer extends Component {

    render() {
        return (
            <>
                <div className="left-0 bottom-0 grid grid-cols-4 gap-5 text-5xl xs:w-[22rem] sm:w-[23rem] md:w-[25rem] lg:w-[28rem] xl:w-[28rem] w-[29rem] xs:ml-4 sm:ml-[9rem] md:ml-[13rem] lg:ml-[22rem] xl:ml-[27rem] ml-[35rem] py-5 neonIcons">
                    <div className="grid grid-cols-1 gap-3">
                        <a href="https://www.instagram.com/abarigamebar/"><FontAwesomeIcon icon={brands('instagram')} className="mx-auto" /></a>
                    </div>

                    <div className="grid grid-cols-1 gap-3">
                        <a href="https://www.facebook.com/abarigamebar/"><FontAwesomeIcon icon={brands('facebook')} className="mx-auto" /></a>
                    </div>
                    <div className="grid grid-cols-1 gap-3">
                        <a href="mailto:info@abarigamebar.com"><FontAwesomeIcon icon={regular('envelope')} className="" /></a>
                    </div>
                    <div className="grid grid-cols-1 gap-3">
                        <a href="https://maps.google.com/?q=1015+Seigle+Ave,+Charlotte,+NC+28205"><FontAwesomeIcon icon={solid('globe')} className="" /></a>
                    </div>
                </div>
            </>
        );
    }
}