import React, { Component } from 'react';
import Header from '../components/header';
import axios from 'axios';
import GameGrid from '../components/GameGrid';


export default class GamesList extends Component {

    state = {
        gamesList: [],
        gamesFilter: 'all',
        filteredList: [],
        thumbsSwiper: []
    }

    imageSlides = [];

    componentDidMount() {
        axios.get("https://qbsgzeafrf.execute-api.us-east-1.amazonaws.com/dev3/game").then(res => {
            const gamesList = Object.values(res.data['data']);
            this.setState({ gamesList: gamesList });
            this.setState({ filteredList: this.sortArray(gamesList) });
        })

    }

    componentDidUpdate(previousProps, previousState) {
        if (previousState.gamesFilter === this.state.gamesFilter || undefined === previousState)
            return;
        this.filterGamesListArray();
    }

    changeFilterState(filter) {
        this.setState({ gamesFilter: filter });
    }

    filterGamesListArray() {
        if (this.state.gamesFilter === 'all') {
            this.setState({ filteredList: this.sortArray(this.state.gamesList.sort()) })
            return;
        }
        const normalizeFilter = this.state.gamesFilter.toUpperCase();
        const filteredArray = this.state.gamesList.filter(el => el.type.toUpperCase() === normalizeFilter);
        this.setState({ filteredList: this.sortArray(filteredArray) })
    }

    sortArray(gamesArray) {
        const sortedArray = gamesArray.sort((a, b) => a.name.localeCompare(b.name));
        console.log(sortedArray);
        return sortedArray;
    }

    render() {
        return (
            <>
                <Header />
                <div className="container mx-auto grid grid-cols-4 grid-col-1 gap-5 xs:w-[20rem] w-[37rem] mt-3">
                    <button onClick={() => this.changeFilterState('all')} className={`${this.state.gamesFilter === 'all' ? 'selectedNeonButtonEffect' : 'neonButtonEffect'} xs:text-[0.55rem] text text-sm font-bold text-center py-2 px-4`}>
                        ALL
                    </button>
                    <button onClick={() => this.changeFilterState('Arcade')} className={`${this.state.gamesFilter === 'Arcade' ? 'selectedNeonButtonEffect' : 'neonButtonEffect'} xs:text-[0.55rem] text-sm font-bold text-center py-2 px-1 border`}>
                        ARCADE
                    </button>
                    <button onClick={() => this.changeFilterState('Console')} className={`${this.state.gamesFilter === 'Console' ? 'selectedNeonButtonEffect' : 'neonButtonEffect'} xs:text-[0.55rem] text-sm font-bold text-center py-2 px-1`}>
                        CONSOLE
                    </button>
                    <button onClick={() => this.changeFilterState('Pinball')} className={`${this.state.gamesFilter === 'Pinball' ? 'selectedNeonButtonEffect' : 'neonButtonEffect'} xs:text-[0.55rem] text-sm font-bold text-center py-2 px-1`}>
                        PINBALL
                    </button>
                </div>

                <GameGrid filteredGames={this.state.filteredList} />
            </>
        );
    }
}