import React from "react";

function GameGrid({ filteredGames }) {
  const GameInfo = ({ currGame }) => {
    return (
      <div className="block rounded transition ease-in-out duration-[200ms] opacity-0 hover:opacity-70 absolute inset-0 text-white bg-black h-auto text-center xs:text-sm sm:text-xs md:text-sm text-xl font-bold ">
        <div className="xs:mt-8 sm:mt-4 md:mt-8 lg:mt-14 xl:mt-20 xs:mb-3 xs:w-[9.5rem] mt-28 mb-3 break-words">
          {currGame.name}
        </div>
        <div className="uppercase">{currGame.type}</div>
      </div>
    );
  };

  const LoadingAnimation = () => {
    return (
      <div className="w-[75%] my-20 mx-auto text-white text-center text-5xl neonLoader">
        <span className="mr-6">Loading</span>
        <span className="w-10 relative mx-auto inline top-8">
          <span className="dot-pulse"></span>
        </span>
      </div>
    );
  };

  if (filteredGames === undefined || filteredGames.length <= 0)
    return <LoadingAnimation />;
  return (
    <div className="grid xs:grid-cols-2 grid-cols-4 my-10 ml-5 mr-5 gap-5">
      {filteredGames.map((game, index) => (
        <div
          key={index}
          className="relative rounded-lg border-4 xs:w-40 sm:w-36 md:w-40 lg:w-60 xl:w-[19rem] w-96 neonGameBorder"
        >
          <img src={game.imgUrl} alt={game.name} className="rounded" />
          <GameInfo currGame={game} />
        </div>
      ))}
    </div>
  );
}

export default GameGrid;
