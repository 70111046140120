import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import sabariLogo from '../assets/SuperAbariGameBar-Vertical-ReverseRGB.png';

export default class Header extends Component {

    render() {
        return (
            <div className="top-0 pt-5 z-50">
                <div className="w-28 mx-auto">
                    <Link to="/">
                        <img src={sabariLogo} alt="Super Abari Logo" className=" object-scale-down" />
                    </Link>
                </div>
                <hr className="border-b border-white xs:w-80 sm:w-[38rem] md:w-[46rem] lg:w-[62rem] xl:w-[77rem] w-[105rem] mx-auto mt-6 mb-1" />
            </div>
        );
    }
}