import React, { Component } from 'react';
import { Link } from "react-router-dom";
import sabariLogoWhite from "../assets/SuperAbariGameBar-Vertical-ReverseRGB.png";

export default class Home extends Component {

    render() {
        return (
            <div className="container mx-auto">
                <div className="block mx-auto xs:w-64 xs:px-10 xs:mr-[4.5rem] pt-10">
                    <img
                        src={sabariLogoWhite}
                        alt="Super Abari Pink and Black Logo"
                        className="relative w-[18rem] xs:mx-auto sm:ml-36 md:ml-52 lg:ml-96 xl:ml-[29rem] xxl:ml-[36rem]"
                    />
                </div>
                <div className="md:ml-20 lg:mr-10 mx-auto xs:w-72 sm:w-[34rem] md:w-[40rem] lg:pl-36 pt-12 grid xs:grid-cols-1 grid-cols-2 xs:gap-16 gap-5">
                    <Link
                        to="/gameslist"
                        className="bg-transparent text-xl font-bold text-center py-2 px-4 neonButtonEffect">
                        Games
                    </Link>
                    <Link
                        to="/menu"
                        className="bg-transparent text-xl font-bold text-center py-2 px-4 neonButtonEffect"
                    >
                        Drink Menu
                    </Link>
                </div>
            </div>
        );
    }
}