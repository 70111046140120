import React from "react";

export default function MenuList({ menu, menuHeader }) {
  const MenuItemsComponent = ({ itemObj }) => {
    if (menuHeader === "Cocktails") {
      return (
        <div className="text-white xs:text-xs text-xl grid grid-cols-3 gap-1 pb-2">
          <p className="text-center my-auto">{itemObj.name}</p>
          <p className=""> {itemObj.description}</p>
          <p className="text-center my-auto">{itemObj.price}</p>
        </div>
      );
    }
    return (
      <div className="text-white xs:text-xs text-xl grid grid-cols-2 gap-10 pb-2">
        <p className="text-center">{itemObj.name}</p>
        <p className="text-center">{itemObj.price}</p>
      </div>
    );
  };
  return (
    <div className="py-5">
      {menu.map((item) => (
        <MenuItemsComponent itemObj={item} />
      ))}
    </div>
  );
}
