import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import GamesList from "./pages/GamesList";
import MenuPage from "./pages/MenuPage";
import Home from "./pages/Home";
import Footer from "./components/footer";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gameslist" element={<GamesList />} />
        <Route path="/menu" element={<MenuPage />} />
      </Routes>
      <div className="container mx-auto my-10">
        <Footer />
      </div>
    </Router>
  );
}

export default App;
